<template>
  <div class="inner-section">
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">
          {{ $t('externalTraining.trainer_evaluation') }}
          <slot v-if="$route.query.id">
            {{ $t('globalTrans.update') }}
          </slot>
          <slot v-else>
            {{ $t('globalTrans.entry') }}
          </slot>
        </h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
            <b-row>
                <b-col sm="4">
                  <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="fiscal_year_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="formData.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              <b-col sm="4">
                <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="circular_memo_no"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="formData.circular_memo_no"
                      :options="circularList"
                      id="circular_memo_no"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-overlay :show="loading">
              <b-row>
                <b-col sm="4">
                  <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="formData.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="training_type_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="training_title_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="Batch" vid="batch_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="batch_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.batch')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.batch_id"
                        :options="batchList"
                        id="batch_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                    <ValidationProvider name="Trainer"  vid="trainer_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="trainer_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_tpm.trainer')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="formData.trainer_id"
                          :options="trainerList"
                          id="trainer_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{ trainerLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
              </b-row>
              <br/>
              <body-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('externalTraining.trainer_evaluation') }}</h4>
                </template>
                <template v-slot:body>
                  <b-table-simple class="mb-0" hover small caption-top responsive bordered>
                      <b-tr>
                        <b-th class="text-left" style="width: 20%">{{$t('globalTrans.sl_no')}}</b-th>
                        <b-th style="width: 40%" class="text-left">{{$t('elearning_config.trainer_evaluation_name')}}</b-th>
                        <b-th style="width: 40%" class="text-left">{{$t('elearning_config.title')}}</b-th>
                      </b-tr>
                      <b-tr v-for="(train,index) in formData.trainer_evaluation_marks" :key="index">
                        <b-td class="text-left">{{$n(index+1)}}</b-td>
                        <b-td class="text-left">
                          {{train.evaluation_name}}
                        </b-td>
                        <b-td style="padding-top: 0px; padding-bottom: 0px;">
                          <ValidationProvider :vid="'trainer_marks' + index" rules="required|min_value:1" name="Assessment">
                            <div slot-scope="{ valid, errors }">
                              <b-form-select
                                :id="'trainer_evaluation_marks['+index+'][grading_status_id]'"
                                v-model="train.grading_status_id"
                                :options="train.gradingStatus"
                                @input="gradingStatusSet(train)"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="error invalid-feedback" role="alert">
                                {{ errors[0] }}
                              </div>
                            </div>
                          </ValidationProvider>
                        </b-td>
                      </b-tr>
                      <template v-if="formData.trainer_evaluation_marks.length === 0">
                        <b-tr>
                          <b-th colspan="3" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</b-th>
                        </b-tr>
                      </template>
                  </b-table-simple>
                </template>
              </body-card>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right">
                  <b-button type="submit" variant="success" class="btn-sm">{{ saveBtnName }}</b-button>
                </div>
              </div>
            </b-overlay>
          </b-form>
        </ValidationObserver>
      </template>
    </body-card>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerEvaluationStore, trainerEvaluationUpdate, circularInfo, trainerEvaluationInfo, trainerEvaluationShow, getTrainer, getTraineeCircular } from '../../api/routes'
import flatpickr from 'flatpickr'
import { dateFormat } from '@/utils/fliter'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData = Object.assign({}, this.formData, {
      fiscal_year_id: this.$store.state.currentFiscalYearId
    })
    if (this.$route.query.id) {
      this.getFormData()
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      circularLoading: false,
      trainerLoading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: 0,
        fiscal_year_id: 0,
        org_id: 0,
        batch_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        status: 1,
        trainer_id: 0,
        trainer_evaluation_marks: []
      },
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerListData: [],
      trainerErrors: [],
      evaluationMarks: [],
      batchList: [],
      circularList: [],
      dateList: [],
      trainerList: []
    }
  },
  computed: {
    trainerEvaluationList: function () {
      return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainerEvaluationList.filter(item => item.org_id === this.formData.org_id)
    },
    gradingStatus: function () {
      return this.$store.state.ExternalUserService.trainingPanel.commonObj.evaluationGradingList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 1)
    },
    officeTypeList: function () {
      return this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1)
    },
    officeList: function () {
      return this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    // batchList: function () {
    //   return this.$store.state.ExternalUserService.trainingPanel.commonObj.batchList.filter(item => item.status === 1)
    // },
    trainingTitleList: function () {
      return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
    // trainerList: function () {
    //   return this.$store.state.ExternalUserService.trainingPanel.commonObj.personalInfoList.filter(item => item.registration_for === 1)
    // }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getTrainerEvaluationMarks()
      }
    },
    'formData.fiscal_year_id': function (newValue) {
      this.getCircularList()
    },
    'formData.trainer_id': function (newValue) {
      this.getTrainerEvaluationMarks()
    },
    'formData.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
    },
    'formData.batch_id': function (newValue) {
      this.getTrainerName(newValue)
    },
    'formData.org_id': function (newValue) {
      this.batchList = this.getBatchList(newValue)
    }
  },
  methods: {
    async getCircularList () {
      this.circularLoading = true
      const params = {
        fiscal_year_id: this.formData.fiscal_year_id,
        trainee_id: this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee.id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, getTraineeCircular, params)
      if (!result.success) {
      } else {
        const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      this.circularLoading = false
    },
    async getTrainerEvaluationMarks () {
      this.loading = true
      const evaluationMarks = this.formData
      evaluationMarks.trainer_evaluation_marks = []
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerEvaluationInfo, evaluationMarks)
      if (!result.success) {
         this.formData.id = 0
        this.formData.trainer_evaluation_marks = []
        this.evaluationMarks = []
      } else {
        this.formData.id = result.data.id
        this.evaluationMarks = result.data.trainer_evaluation_marks
      }
      this.trainerEvaluationList.map(item => {
        const evaluationMarks = this.evaluationMarks.find(evaluationMark => parseInt(evaluationMark.trainer_evaluation_detail_id) === parseInt(item.value))
        const obj = {
          trainer_evaluation_detail_id: item.value,
          evaluation_name: this.currentLocale === 'en' ? item.text_en : item.text_bn,
          grading_status_id: typeof evaluationMarks !== 'undefined' ? evaluationMarks.grading_status_id : 0,
          attain_marks: typeof evaluationMarks !== 'undefined' ? evaluationMarks.attain_marks : 0,
          gradingStatus: this.gradingStatus
        }
        this.formData.trainer_evaluation_marks.push(obj)
      })
      this.loading = false
    },
    gradingStatusSet (data) {
      const gradeList = this.gradingStatus.find(gradingStatus => gradingStatus.value === data.grading_status_id)
      data.attain_marks = gradeList.average_number
      return data
    },
    getBatchList (orgId) {
      const batchList = this.$store.state.ExternalUserService.trainingPanel.commonObj.batchList.filter(item => item.status === 1)
      if (orgId) {
        return batchList.filter(item => item.org_id === orgId)
      }
      return batchList
    },
    removeAttachment (index, data) {
      this.formData.module_attachments.splice(index, 1)
      if (data.pre_attachment) {
        this.formData.module_attachments_remove.push(data)
      }
    },
    async getFormData () {
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerEvaluationShow + '/' + this.$route.query.id)
      if (!result.success) {
        this.formData = []
      } else {
        this.formData = result.data
      }
      this.loading = false
    },
    async getCircularMemoNo (getCircularMemoNo) {
      this.loading = true
      const circular = {
        circular_memo_no: getCircularMemoNo
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularInfo, circular)
      if (!result.success) {
        this.formData.fiscal_year_id = 0
        this.formData.org_id = 0
        this.formData.training_title_id = 0
        this.formData.training_type_id = 0
        this.courseList = []
        this.trainerList = []
        // this.$toast.error({
        //   title: 'Error',
        //   message: result.message,
        //   color: '#D6E09B'
        // })
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.formData.fiscal_year_id = trData.fiscal_year_id
        this.formData.org_id = trData.org_id
        this.formData.training_title_id = trData.training_title_id
        this.formData.training_type_id = trData.training_type_id
        // this.trainerList = result.trainerData.map(item => {
        //   return { value: item.value, text: this.currentLocale === 'en' ? item.text_en : item.text_bn, gradingStatus: this.gradingStatus, grading_status_id: 0 }
        // })
        const listData = result.aryRange.map(item => {
            const updateData = {}
            updateData.checked = false
            updateData.value = item
            updateData.text = dateFormat(item)
          return Object.assign({}, updateData)
        })
        this.dateList = listData
      }
      this.loading = false
    },
    async getTrainerName () {
      this.trainerLoading = true
      const params = {
        circular_memo_no: this.formData.circular_memo_no,
        batch_id: this.formData.batch_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, getTrainer, params)
      if (!result.success) {
        this.trainerList = []
        this.trainerLoading = false
      } else {
        this.trainerList = result.data.map(item => {
          return { value: item.value, text: this.currentLocale === 'en' ? item.text_en : item.text_bn }
        })
        this.trainerLoading = false
      }
    },
    async createData () {
      this.loading = true
      let result = null
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainerEvaluationUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainerEvaluationStore, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$router.push('/training-panel/trainer-evaluation')
      } else {
        if (result.errors) {
          this.trainerErrors = result.errors
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
  }
  }
}
</script>
