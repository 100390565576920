// Circular Publication api
export const circularPublicationBaseUrl = '/iabm/circular-publications/'
export const circularPublicationList = circularPublicationBaseUrl + 'listTT'
export const circularPublications = circularPublicationBaseUrl + 'circular-publication-list'
export const circularPublicationToggleStatus = circularPublicationBaseUrl + 'toggle-status'
export const circularPublicationStore = circularPublicationBaseUrl + 'store'
export const circularPublicationUpdate = circularPublicationBaseUrl + 'update'
export const circularPublicationDestroy = circularPublicationBaseUrl + 'destroy'

// profile store api
export const profileStore = '/personal-infos/store-update'
export const profileShow = '/personal-infos/show'
export const profileInfo = '/personal-infos/profile-info'

// training application api
export const trainingAppliedList = '/external-user/training-applications/applied'
export const trainingEkpayMessage = '/external-user/training-payment/payment-status/'
export const trainingApplicationStore = '/external-user/training-applications/store'
export const trainingApplicationUpdate = '/external-user/training-applications/update/'
export const trainingApplicationShow = '/external-user/training-applications/show'
export const bookingApplicationList = '/external-user/training-applications/booking-list'
export const certificateIndividualList = '/external-user/training-applications/certificate-list'
export const trainerSpecificCircularList = '/external-user/training-applications/trainer-specific-circular-list'
export const trainerBaseSpecificCircularList = '/external-user/training-applications/trainer-base-specific-circular-list'
export const trainerCircularMemoSpecificList = '/external-user/training-applications/trainer-circular-memo-specific-list'
export const trainerCircularOrgSpecificList = '/external-user/training-applications/trainer-circular-org-specific-list'
export const trainerEvaluationSpecificBatchList = '/external-user/training-applications/trainer-evaluation-specific-batch-list'

// Request for document api
export const requestDocumentList = '/external-user/request-for-document/list'
export const requestDocumentStore = '/external-user/request-for-document/store'
export const requestDocumentUpdate = '/external-user/request-for-document/update'
export const requestDocumentToggleStatus = '/external-user/request-for-document/toggle-status'

export const complainList = '/iabm/complain-recommendation/list-tt'
export const complainStore = '/externalUser/complain/store'
export const complainUpdate = '/externalUser/complain/update'
export const complainToggleStatus = '/externalUser/complain/toggle-status'
export const faqSetupList = '/externalUser/faq-setup/list'

export const trainingScheduleBaseUrl = '/externalUser/training-schedule/'
export const trainingScheduleList = trainingScheduleBaseUrl + 'list'
export const trainingScheduleCommittee = '/tpm/training-schedule/committee'
export const circularNoList = trainingScheduleBaseUrl + 'circular-no'

// Booking api

// payment api
export const billPaymentBoucher = '/externalUser/bill-payment/boucher'

// Evaluation api
export const evaluationBaseUrl = '/external-user/evaluations/'
export const evaluationList = evaluationBaseUrl + 'list'
export const evaluationShow = evaluationBaseUrl + 'show'

// Evaluation api
export const courseEvaluationBaseUrl = '/external-user/course-evaluations/'
export const courseEvaluationList = courseEvaluationBaseUrl + 'list'
export const courseEvaluationStore = courseEvaluationBaseUrl + 'store'
export const courseEvaluationShow = courseEvaluationBaseUrl + 'show'

// Trainer Evaluation
export const circularInfo = '/external-user/trainer-evaluation/circular-info'
export const getTrainer = '/external-user/trainer-evaluation/trainer'
export const getTraineeCircular = '/external-user/trainer-evaluation/trainee-circular'
export const trainerEvaluationInfo = '/external-user/trainer-evaluation/trainer-evaluation-info'
export const trainingModuleWiseTrainer = '/tpm/training-schedule/module-wise-trainer'
export const trainerEvaluationList = '/external-user/trainer-evaluation/list'
export const trainerEvaluationStore = '/external-user/trainer-evaluation/store'
export const trainerEvaluationShow = '/external-user/trainer-evaluation/show'
export const trainerEvaluationUpdate = '/external-user/trainer-evaluation/update'
export const trainerEvaluationToggleStatus = '/external-user/trainer-evaluation/toggle-status'

export const traineeIdCard = '/external-user/trainee-id-card'
export const traineeIdCardPrint = '/external-user/trainee-id-card/'

export const certificateBaseUrl = '/tim/certificate/'
export const certificateIndividualShow = certificateBaseUrl + 'certificateShow'
export const certificateList = certificateBaseUrl + 'list'
export const certificateDetails = certificateBaseUrl + 'show'
export const certificateIndividualPdf = certificateBaseUrl + 'certificatePdf'

export const reportHeadingList = '/config/report-head/list'

export const reportHeadingListNew = '/config/report-head/listnew'
export const certificateDetail = ''

// Vanue Management api
// export const reportHeadingList = '/config/report-head/list'
export const reportHeadingStore = '/config/report-head/store'
export const reportHeadingUpdate = '/config/report-head/update'
export const reportToggleStatus = '/config/report-head/toggle-status'
export const reportHeadDetails = '/config/report-head/detail'
export const reportHeadingDestroy = '/config/report-head/destroy'

// routine
export const trainingPublicationBaseUrl = '/iabm/circular-publications/'
export const circularApproveList = trainingPublicationBaseUrl + 'circular-publication-list'
export const trainingSchedul = '/tpm/training-schedule/'
export const trainingScheduleCircularData = trainingSchedul + 'circular-data'

export const trainingApplicationListPaginate = '/iabm/booking/application'
export const TrainerSpecificTrainingApplicationList = '/external-user/booking/application'
export const trainingApplicationTrainerAllTraineeBatch = '/external-user/booking/traineralltraineebatches'

export const trainerTraineeRainingApplicationBaseUrl = '/iabm/training-applications/'
export const trainerTraineeRainingApplicationAllList = trainerTraineeRainingApplicationBaseUrl + 'application-list'
export const traineeEvaluationFrom = trainerTraineeRainingApplicationBaseUrl + 'application-list'

export const trainerTraineeTrainerSortingBaseUrl = '/tpm/trainer-sorting/'
export const trainerTraineeTrainerSortingStore = trainerTraineeTrainerSortingBaseUrl + 'store'

export const trainerTraineeTrainingPublicationBaseUrl = '/iabm/circular-publications/'
export const trainerTraineeCircularPublicationList = trainerTraineeTrainingPublicationBaseUrl + 'circular-publication-list'
export const trainerTraineeCircularPublication = trainerTraineeTrainingPublicationBaseUrl + 'circular-publication-data'

export const batchWiseTrainee = '/external-user/pre-course-evaluations/batch-wise-trainee'
export const manualCourseEvaluationStore = '/external-user/pre-course-evaluations/store'
export const manualCourseEvaluationSearch = '/external-user/manual-course-evaluation-search/circularsearch'

// Training Payment Apis
export const paymentOfflineApi = '/external-user/training-payment/offline'
export const paymentReceiptApi = '/external-user/training-payment/payment-receipt'
export const paymentOnlineApi = '/external-user/training-payment/online-payment'
export const traingAppDetailsApi = '/external-user/training-payment/app-details'
export const trainingAppInfoApi = '/external-user/training-payment/app-info'
export const paymentDetailsApi = '/external-user/training-payment/app-payment'

// circular memo list
export const circularPublishedList = '/iabm/circular-publications/' + 'circular-publication-list'
export const circularData = '/iabm/circular-publications/' + 'circular-publication-data'
